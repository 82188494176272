<template>
  <v-sheet
    elevation="2"
    class="white mt-6 mx-4 px-4"
    rounded
  >
    <v-form
      ref="form"
    >
      <v-row>
        <v-col offset-sm="2" cols="12" sm="3">
          <label for="shipper" class="font-weight-bold">{{$_strings.manualOrders.SHIPPER}}</label>
        </v-col>
        <v-col cols="12" sm="4" class="pt-0 pt-sm-2">
          <common-auto-complete-items
            id="shipper"
            type="masterShipper"
            searchName="companyName"
            item-value="id"
            item-text="companyName"
            v-model="form.shipperId"
            dense
            outlined
            class="caption"
            clearable
            :placeholder="`${$_strings.manualOrders.SHIPPER}`"
            :filter="shipperFilter"
            :items="itemsShipper"
            :rules="[v => !!v || $_strings.messages.validation.REQUIRED($_strings.manualOrders.SHIPPER)]"
            @updateItems="updateListShipper"
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="auto">
          <v-btn
            color="primary"
            outlined
            :loading="downloadingCsv"
            @click="downloadCsvTemplate"
          >
            Template CSV
          </v-btn>
        </v-col>
        <v-col cols="auto" v-if="menuFunctions.CREATE_PESANAN_MANUAL">
          <v-btn
            color="primary"
            @click="$refs.fileInput.click()"
            :loading="uploadingCsv"
            :disabled="!form.shipperId"
          >
            Upload Pesanan
          </v-btn>
        </v-col>
        <v-col cols="auto" v-if="menuFunctions.CREATE_PESANAN_MANUAL">
          <v-btn
            color="primary"
            @click="submit"
          >
            Berikutnya
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <input
      style="display: none;"
      id="csv-upload"
      :disabled="uploadingCsv"
      ref="fileInput"
      type="file"
      accept=".csv"
      @change="uploadCsv"
    />
  </v-sheet>
</template>

<script>
import dayjs from 'dayjs';
import { getDateTimeTz, parseCsvRow } from '@/helper/commonHelpers';

const { exportCSVFile } = require('../../helper/csvGenerator');

export default {
  data() {
    return {
      downloadingCsv: false,
      uploadingCsv: false,
      form: {
        shipperId: null,
        mouId: null,
      },
      itemsShipper: [],
      shipperFilter: {
        search: '',
        page: 0,
        size: 25,
        totalData: 0,
      },
    };
  },
  methods: {
    dayjs,
    getDateTimeTz,
    async downloadCsvTemplate() {
      try {
        this.downloadingCsv = true;
        const result = await this.$_services.order.templateShipmentShipperCSV();
        const { column, filename } = result.data;
        exportCSVFile(null, [[...column]], filename);
      } finally {
        this.downloadingCsv = false;
      }
    },
    mapToForm(data) {
      const {
        TANGGAL_PICKUP,
        SERVICE_TYPE,
        TRANSPORT_TYPE,
        KATEGORI_BARANG,
        SUB_KATEGORI_BARANG,
        LOKASI_ASAL,
        LOKASI_TUJUAN,
        KATEGORI_BARANG_BACKHAULING,
        SUB_KATEGORI_BARANG_BACKHAULING,
        LOKASI_ASAL_BACKHAULING,
        LOKASI_TUJUAN_BACKHAULING,
        NO_TLP_PENERIMA_BACKHAULING,
      } = data;

      // Membentuk objek dengan format yang diinginkan

      return {
        pickupDate: dayjs(TANGGAL_PICKUP).format(),
        serviceType: SERVICE_TYPE,
        transportTypeId: TRANSPORT_TYPE,
        categoryId: KATEGORI_BARANG,
        subCategoryId: SUB_KATEGORI_BARANG,
        originLocationId: LOKASI_ASAL,
        destinationLocationId: LOKASI_TUJUAN,
        backhauling: KATEGORI_BARANG_BACKHAULING || SUB_KATEGORI_BARANG_BACKHAULING
                     || LOKASI_TUJUAN_BACKHAULING || NO_TLP_PENERIMA_BACKHAULING
          ? {
            categoryId: KATEGORI_BARANG_BACKHAULING,
            subCategoryId: SUB_KATEGORI_BARANG_BACKHAULING,
            destinationLocationId: LOKASI_TUJUAN_BACKHAULING,
            destinationReceiverPhone: NO_TLP_PENERIMA_BACKHAULING,
            shipmentsDetail: [],
          }
          : null, // Jika tidak ada data backhauling, maka set ke null
        items: {
          itemsVehicle: [{
            transportTypeId: TRANSPORT_TYPE,
            name: TRANSPORT_TYPE,
          }],
          itemsOrigin: [{
            value: LOKASI_ASAL,
            text: LOKASI_ASAL,
          }],
          itemsDestination: [{
            value: LOKASI_TUJUAN,
            text: LOKASI_TUJUAN,
          }],
          itemsCategory: [{
            id: KATEGORI_BARANG,
            name: KATEGORI_BARANG,
          }],
          itemsSubCategory: [{
            id: SUB_KATEGORI_BARANG,
            name: SUB_KATEGORI_BARANG,
          }],
          // ITEMS FOR BACKHAULING
          itemsOriginBackhauling: [{
            value: LOKASI_ASAL_BACKHAULING,
            text: LOKASI_ASAL_BACKHAULING,
          }],
          itemsDestinationBackhauling: [{
            value: LOKASI_TUJUAN_BACKHAULING,
            text: LOKASI_TUJUAN_BACKHAULING,
          }],
          itemsSubCategoryBackhauling: [{
            id: SUB_KATEGORI_BARANG_BACKHAULING,
            name: SUB_KATEGORI_BARANG_BACKHAULING,
          }],
        },
      };
    },
    async uploadCsv() {
      const file = this.$refs.fileInput.files[0];
      const type = file.name.split('.').splice(1, file.name.split('.').length)[0];
      const typeSupport = ['csv'];
      if (!typeSupport.includes(type)) {
        this.$dialog.notify.error('Format file tidak didukung');
        return;
      }
      const formData = new FormData();
      formData.append('timezone', this.getDateTimeTz());
      formData.append('file', file);
      formData.append('companyId', this.form.shipperId);
      const reader = new FileReader();
      const orders = [];
      const _this = this;
      // eslint-disable-next-line func-names
      reader.onload = function (e) {
        const text = e.target.result;
        const csvData = text.split('\n'); // Memisahkan baris CSV
        const [headerRow, dataRow] = csvData;
        const allHeaders = headerRow.split(';');

        // Pisahkan header dan data
        const desiredHeaders = [
          'SERVICE_TYPE',
          'TRANSPORT_TYPE',
          'KATEGORI_BARANG',
          'SUB_KATEGORI_BARANG',
          'LOKASI_ASAL',
          'LOKASI_TUJUAN',
          'KATEGORI_BARANG_BACKHAULING',
          'SUB_KATEGORI_BARANG_BACKHAULING',
          'LOKASI_ASAL_BACKHAULING',
          'LOKASI_TUJUAN_BACKHAULING',
        ];
        const result = parseCsvRow(dataRow, desiredHeaders, allHeaders);
        orders.push(_this.mapToForm(result));
      };

      reader.readAsText(file);
      reader.onloadend = () => {
        this.navigateToPageUpload({ orders, formData });
      };
      const input = this.$refs.fileInput;
      input.type = 'text';
      input.type = 'file';
    },
    updateListShipper(items) {
      this.itemsShipper = [...this.itemsShipper, ...items];
    },
    navigateToPageUpload({ orders, formData }) {
      this.$router.push({
        name: 'create-manual-order',
        params: {
          orders,
          upload: true,
          formData,
        },
        query: {
          mouId: this.form.mouId,
          shipperId: this.form.shipperId,
        },
      });
    },
    submit() {
      this.form.mouId = this.itemsShipper.find((x) => x.id === +this.form.shipperId)?.mouId;
      const valid = this.$refs.form.validate();
      if (valid) {
        if (!this.form.mouId) return this.$dialog.notify.error('Mou tidak ditemukan');
        this.$router.push({
          name: 'create-manual-order',
          query: {
            mouId: this.form.mouId,
            shipperId: this.form.shipperId,
          },
        });
      }
    },
  },
};
</script>
